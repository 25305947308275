/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../_metronic/helpers';

import Card from './components/Card';
import BorderedCard from './components/BorderedCard';
import { Link } from 'react-router-dom'

import Discount from './components/discount.png';
import Phone from './components/phone.png';
import Meeting from './components/meeting.png';
import Info from './components/information.png';
//import TablesWidget from './components/TablesWidget';
import {
	StatisticsWidget1
} from '../../../_metronic/partials/widgets';
import moment from "moment";
import { getLists, updateLeads, postChat, postMail } from './redux/DashboardCrud'
import { useSelector } from "react-redux";
import { Pie, Column } from '@ant-design/plots';
import _ from "lodash";
import TextField from '@material-ui/core/TextField';
import ReactTags from 'react-tag-autocomplete';
import { useLang } from '../../../_metronic/i18n/Metronici18n'


export default function DashboardWrapper() {

	const reactTags = useRef();
	const intl = useIntl();
	const [leads, setLeads] = React.useState([]);
	const [upcomingleads, setUpcomingleads] = React.useState([]);
	const [suggestions, setSuggestion] = React.useState([]);

	const [modalInfo, setModalInfo] = React.useState([]);
	const [customerData, setCustomerData] = React.useState({})
	const [visible, setVisible] = React.useState(false);
	const { data } = useSelector(({ auth }) => auth.user);
	const [yearWiseMeeting, setYearWiseMeeting] = useState([]);
	const [last30Days, setLast30Days] = useState([]);
	const [leadComment, setLeadComment] = useState();
	const [leadStatus, setLeadStatus] = useState();
	const [totalLeads, setTotalLeads] = useState([]);
	const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'));
	const [currentUser, setCurrentUser] = useState({});
	const [leadchat, setLeadChat] = useState([]);
	const [leadChatComments, setLeadChatComments] = useState('');
	const [commentBox, setCommentbox] = useState(false);

	const [emailModal, setEmailModal] = React.useState(false);
	const [selectedItem, setSelectedItem] = React.useState(null);
	const [tags, setTags] = React.useState([]);
	const [mailstatus, setMailStatus] = React.useState(null);
	const [report, setReport] = useState('');
	const [statusimages] = useState({
		"OFFER": Discount,
		"PHONE": Phone,
		"MEETING": Meeting,
		"INFO": Info
	})
	const [statuscolors] = useState({
		"OFFER": '#4E9F3D',
		"PHONE": '#41c4f1',
		"MEETING": '#4E9F3D',
		"INFO": '#FFD07F'
	});

	const statusName = {
		"PHONE": 'Samtal',
		"MEETING": 'Möte',
		"INFO": 'Information'
	}

	const Colors = {
		0: "#4E9F3D",
		1: "#41c4f1",
		2: "#faad14",
		3: "#722ed1",
		4: "#ff7d0e",
		5: "#13c2c2",
		6: "#eb2f96",
	}
	const [leadsCount, setLeadsCount] = useState(0);
	const todayDate = moment().format('YYYY-MM-DD');
	const selectedLang = useLang()
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const [localLanguage, setLocalLanguage] = useState({})
	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	const openModal = (data) => {

		if ((data.leadstatus == '' || data.leadstatus == null) && !data.isread) {
			updateLeads(data.id, { isread: true });
			data.isread = true;
		}

		let filter = `?filter[lead][eq]=${data.id}&fields=*`

		getLists('LEAD_CHATS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setLeadChat(data);
				} else {
					setLeadChat([])
				}
			})
			.catch((err) => {
				setLeadChat([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})

		setVisible(true);
	};

	useEffect(() => {

		if (data && data.id) {

			setCurrentUser(data)
			getCustomerData(data)
			getLeadsList(data)
			getLeadsTodayCount(data)
			//getCustomerReport(data)
			getUpcomingLeadsList(data, filterDate);
			getSalesrepData(data);
			getSettings();

		}

	}, [data]);

	useEffect(() => {
		if (leadStatus != '') {
			updateLeadData();
		}
	}, [leadStatus]);

	useEffect(() => {
		let comnts = '';
		setLeadChatComments('');
		if (leadchat) {
			comnts += '<ul>';
			leadchat.map((item, index) => {
				index % 2 ?
					comnts += '<li style="list-style-position: inside;">' + (item.comment) + '</li>' :
					comnts += '<li>' + (item.comment) + '</li>'

			});
			comnts += '</ul>';
			setLeadChatComments(comnts);

		}
	}, [leadchat]);

	const getSalesrepData = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*`;

		getLists('CUSTOME_SALES_REP', filter, accessToken)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					let reps = [];
					data.map((item, index) => {
						reps.push({ id: item.id, name: item.email });
					});
					//console.log('sales data',reps);
					setSuggestion(reps);
					setTags(reps);
				} else {
					setSuggestion([]);
				}
			})
			.catch((err) => {
				setSuggestion([]);
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			});
	};

	const getCustomerData = (data, accessToken) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`

		getLists('COMPANY_CURRENT_STATUS', filter, accessToken)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setCustomerData(data[0]);
					// console.log('company data', data[0]);
				} else {
					setCustomerData([])
				}
			})
			.catch((err) => {
				setCustomerData([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const updateLeadData = () => {

		let param = {
			leadcomment: leadComment,
		}
		if (leadStatus) {
			param.leadstatus = leadStatus;
		}
		if (leadComment) {
			param.leadcomment = leadComment;

			/*let params = {
				comment : leadComment,
				lead : modalInfo.id
			}
			postChat(params);*/

		}
		//if (!leadStatus && !leadComment) {
		if (!leadStatus) {
			return false;
		}

		updateLeads(modalInfo.id, param)
			.then(({ data: { data } }) => {
				setModalInfo([])
				setLeadComment('')
				getLeadsList(currentUser);
				setCommentbox(false);
			})
			.catch((err) => {
				setLeadComment('')
				setLeadStatus('')
				throw err;
			})
	}

	const onDelete = useCallback((tagIndex) => {
		setTags(tags.filter((_, i) => i !== tagIndex))
	}, [tags])

	const onAddition = useCallback((newTag) => {
		setTags([...tags, newTag])
	}, [tags])

	// Send lead mail
	const sendEmail = () => {
		let emailValue = [];
		//alert(JSON.stringify(tags));
		if (tags) {
			tags.map((item, index) => {
				emailValue.push(item.name);
			});
		}

		if (emailValue.length == 0) {
			//alert('Please enter at least one E-Mail');
			setMailStatus('NO_REP');
			return;
		}


		const values = [];

		values['to'] = emailValue;
		let content = '<table style="font-size: 14px;width:100%"><tr style="font-weight: 700">Lead Details Below:</tr><tr></tr><tr><td style="font-weight: 600;">Business Name:</td></tr><tr><td>{{businessname}}</td></tr><tr><td style="font-weight: 600;">Contact Name:</td></tr><tr><td>{{contactname}}</td></tr><tr><td style="font-weight: 600;">Contact Mail:</td></tr><tr><td>{{contactmail}}</td></tr><tr><td style="font-weight: 600;">Contact Phone:</td></tr><tr><td>{{contactphone}}</td></tr><tr><td style="font-weight: 600;">Lead Score:</td></tr><tr><td>{{leadscore}}</td></tr><tr><td style="font-weight: 600;">Lead Info:</td></tr><tr><td style="width: 80%">{{leadinfo}}</td></tr><tr><td style="font-weight: 600;">Conversation:</td></tr><tr><td style="width: 80%">{{comments}}</td></tr><td style="font-weight: 600;"><a href="https://app.saljare.se/" target="_blank" type="button" class="btn btn-default">See lead</a></td></tr></table>';
		values['body'] = content;
		values['subject'] = `New Lead`;

		let params = {
			...values,
			type: "html",
			data: {
				companyname: customerData.industry || '-',
				businessname: selectedItem.business_name || '-',
				contactname: selectedItem.contactname || '-',
				leadscore: selectedItem.leadscore || '-',
				leadstatus: selectedItem.leadstatus || '-',
				contactmail: selectedItem.contactmail || '-',
				contactphone: selectedItem.contactphone || '-',
				leadinfo: selectedItem.leadinfo || '-',
				comments: leadChatComments || '-'
			}
		};

		postMail(params)
			.then(({ data: { data } }) => {
				updateLeads(selectedItem.id, { ismobilesent: 'SENT' });
				setMailStatus('SUCCESS');
				setEmailModal(false);
				setVisible(false);
			})
			.catch((err) => {
				throw err;
			});

	};

	// GET LEADS LIST
	const getLeadsList = (data) => {

		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*&sort=-created_on&limit=12`

		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setVisible(false)

					setLeads(data);
					data.forEach((item) => {
						item.year = item.leaddate ? moment(item.leaddate).format('YYYY') : 'Other'
						item.year_month = item.leaddate ? moment(item.leaddate).format('YYYY-MM') : 'Other'
					})
					const total = _(data)
						.groupBy('leadstatus')
						.map(group => ({
							name: group[0]?.leadstatus ? group[0]?.leadstatus === 'WON' ? 'Godkända' : 'Nekade' : 'Pågående',
							"value": group.length,
							"color": group[0]?.leadstatus ? group[0]?.leadstatus === 'WON' ? '#41c4f1' : '#3f4254' : '#4E9F3D',
						}))
						.value();
					const currentDate = new Date();

					const currentDateTime = currentDate.getTime();
					// console.log(currentDate.getDate(), "x.leaddate", currentDate)

					const last30DaysDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
					const last30DaysDateTime = last30DaysDate.getTime();

					const last30DaysList = data.filter(x => {
						const elementDateTime = new Date(x.leaddate).getTime();
						// console.log("elementDateTime",elementDateTime)
						// console.log("last30DaysDateTime",last30DaysDateTime)

						if (elementDateTime <= currentDateTime && elementDateTime > last30DaysDateTime) {
							return true;
						}
						return false
					}).sort((a, b) => {
						return new Date(b.datum) - new Date(a.datum);
					});

					//console.log('last30days',last30DaysList)
					const last30Days_List = _(last30DaysList)
						.groupBy('leadstatus')
						.map(group => ({
							name: group[0]?.leadstatus ? group[0]?.leadstatus === 'WON' ? 'Godkända' : 'Nekade' : 'Pågående',
							"value": group.length
						}))
						.value();
					setLast30Days(last30Days_List)
					setTotalLeads(total)
					// setYearWiseMeeting(yeargroups)
					// setMonthlyWiseMeeting(monthsgroups)
					// console.log('groups1', monthsgroups);

				} else {
					setLeads([])
				}
			})
			.catch((err) => {
				setLeads([])
				setVisible(false)

				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const getLeadsTodayCount = (data) => {

		let filter = `?filter[customer_id][eq]=${data.id}&meta=result_count%2Cfilter_count&filter[created_on][contains]=${todayDate}&fields=id`

		getLists('LEADS', filter)
			.then(({ data: { meta } }) => {
				if (meta) {
					setLeadsCount(meta.filter_count);
				} else {
					setLeadsCount(0)
				}
			})
			.catch((err) => {
				setLeadsCount(0)

				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}


	const getUpcomingLeadsList = (data, date) => {
		// if (e?.target?.value) {
		// 	setFilterDate(e?.target?.value)
		// }
		let query = `&filter[customer_id][eq]=${data.id}`
		if (date) {
			query += `&filter[leaddate][gte]=${moment(date).format('YYYY/MM/DD')}`
		}
		let fields = `?fields=*.*.*`
		let filter = `${fields}${query}`

		getLists('LEADS', filter)
			.then(({ data: { data } }) => {
				if (data && data.length > 0) {
					setUpcomingleads(data);
				} else {
					setUpcomingleads([])
				}
			})
			.catch((err) => {
				setUpcomingleads([])
				throw err;
				// setIsloading(false)
				//   setStatus('The login detail is incorrect')
			})
	}

	const Pie1 = () => {
		const config = {
			appendPadding: 10,
			yearWiseMeeting,
			appendPadding: 10,
			data: last30Days,
			angleField: 'value',
			colorField: 'name',
			color: ['#41c4f1', '#4E9F3D', '#3f4254'],
			radius: 1,
			innerRadius: 0.6,
			label: {
				type: 'outer',
				content: '{name} {value}',
			},
			interactions: [
				{
					type: 'element-active',
				},
			],
		};
		return <Pie {...config} />;
	};

	const Pie2 = () => {
		const config = {
			appendPadding: 10,
			yearWiseMeeting,
			appendPadding: 10,
			data: totalLeads,
			angleField: 'value',
			colorField: 'name',
			color: ['#41c4f1', '#4E9F3D', '#3f4254'],
			radius: 1,
			innerRadius: 0.6,
			label: {
				type: 'outer',
				content: '{name} {value}',
			},
			interactions: [
				{
					type: 'element-active',
				},
			],
		};
		return <Pie {...config} />;
	};

	const getSettings = () => {
		let filter = `/1?fields=*.*.*`;

		getLists('SETTINGS', filter)
			.then(({ data: { data } }) => {
				//console.log('customer reports', data);
				if (data) {
					setReport(data);

					//window.location.reload();
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<div style={{ width: '103%' }}>

			<h2 className="mb-10">{localLanguage.overview}</h2>

			<div className="row">
				<div className="col-xl-8" style={{ padding: 0 }}>
					<div className="card-white mb-5">
						<div className="flex-row" style={{ justifyContent: 'space-between' }}>
							<h4 className="ml-15">{localLanguage.leads}</h4>
							<Link
								to="/list"
								className="btn btn-link btn-color-info btn-active-color-primary p-0"
							>
								{localLanguage.all_leads}
							</Link>
						</div>

						<div className="row">
							{
								leads && leads.map((item, index) => (
									<div className="col-xl-3">
										<Card
											key={index}
											designation={item.business_name ? item.business_name : '-'}
											title={item.contactname ? item.contactname : '-'}
											email={item.contactmail ? item.contactmail : '-'}
											buttonName={item.leadscore ? item.leadscore : '-'}
											// image={imagesPhone}
											iconColor={item.status_type ? statuscolors[item.status_type] : ''}
											image={item.status_type ? statusimages[item.status_type] : ''}
											status_type={item.status_type ? statusName[item.status_type] : ''}
											data={item}
											onclick={() => { setSelectedItem(item); setModalInfo(item); openModal(item); }}
										/>
									</div>
								))
							}
						</div>
					</div>
				</div>
				<div className="col-xl-4">
					<div className="card-white upcoming_meets">
						<div className="flex-row" style={{ justifyContent: 'space-between' }}>
							<h5 className="ml-5 mr-10">{localLanguage.follow_ups}</h5>
							<TextField id="date"
								label=""
								type="date"
								defaultValue={filterDate}
								style={{ width: 120, marginTop: -3, marginRight: -10 }}
								onChange={(e) => {
									//alert(e.target.value);
									setFilterDate(e.target.value);
									getUpcomingLeadsList(data, e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
							{/* <DatePicker /> */}
						</div>
						<div className="flex-column">
							{
								upcomingleads ? upcomingleads.map((item, index) => (
									<BorderedCard
										title={item.business_name ? item.business_name : '-'}
										borderColor={Colors[index]}
										icon={'bi bi-arrow-up fs-1x'}
										iconColor={'red'}
										onclick={() => { setModalInfo(item); openModal(item); }}
										subTitle={item.leaddate ? moment(item.leaddate).calendar() : ''}
										buttonName={''}
									/>
								)) : <div style={{ height: 80, textAlign: 'center', margin: 50, color: '#f1416cd1' }}>Inga kommande möten</div>
							}
						</div>
					</div>
					<div className="card-white vid-part">
						<div className="flex-row" style={{ justifyContent: 'space-between' }}>
							<h5 className="ml-5 mr-10">{localLanguage.how_to_use_portal}</h5>

						</div>
						<div className="flex-column vid-play">
							<a target="_blank" href={report?.dashboardvideo_url?.data?.full_url}>
								<video className="video"
									playsInline
									muted
									controls
									src={report?.dashboardvideo_url?.data?.full_url}
								/>
							</a>

						</div>
					</div>
				</div>

			</div>

			{/* Info  */}

			<div className="mt-5 mb-5">
				{/* begin::Row */}
				<div className="row g-5 g-xl-8">
					<div className="col-xl-4">
						<StatisticsWidget1
							className="mb-5 mb-xl-8"
							image="abstract-4.svg"
							title={localLanguage.title}
							time=""
							description=""
							titleColor="#000"
						/>
					</div>

					<div className="col-xl-4">
						<StatisticsWidget1
							className="mb-5 mb-xl-8"
							image="abstract-2.svg"
							title={localLanguage.industry}
							time=""
							description=""
							titleColor="#000"
						/>
					</div>

					<div className="col-xl-4">
						<StatisticsWidget1
							className="mb-5 mb-xl-8"
							image="abstract-1.svg"
							title={localLanguage.no_of_leads}
							time=""
							description=""
							titleColor="#000"
						/>
					</div>
				</div>

				{/* begin::Row */}
				<div className="row g-5 g-xl-8">
					<div className="col-xl-4">
						<StatisticsWidget1
							className="btn-block-blue mb-5 mb-xl-8"
							image="abstract-2.svg"
							title={customerData?.title}
							time=""
							description=""
							titleColor="#000"
						/>
					</div>
					<div className="col-xl-4">
						<StatisticsWidget1
							className="btn-block-blue mb-5 mb-xl-8"
							image="abstract-4.svg"
							title={customerData?.industry}
							time=""
							description=""
							titleColor="#000"
						/>
					</div>
					<div className="col-xl-4">
						<StatisticsWidget1
							className="btn-block-blue mb-5 mb-xl-8"
							image="abstract-3.svg"
							title={leadsCount}
							time=""
							description=""
							titleColor="#000"
						/>
					</div>


				</div>
			</div>

			{/* Chart Reports */}
			<div className="row g-5 g-xl-8">
				<div className="col-xl-6 col-xs-12">
					<div className="card-white mb-5">
						<div className="flex-row mb-5" style={{ justifyContent: 'space-between' }}>
							<h4 className="ml-15">{localLanguage.last_30_days}</h4>
						</div>
						<Pie1 />
					</div>
				</div>
				<div className="col-xl-6 col-xs-12">
					<div className="card-white mb-5">
						<div className="flex-row mb-5" style={{ justifyContent: 'space-between' }}>
							<h4 className="ml-15">{localLanguage.then_start}</h4>
						</div>
						<Pie2 />
					</div>
				</div>

			</div>

			{/* start::Email modal */}

			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={emailModal}
				dialogClassName="modal-lg"
				aria-hidden="true"
			>

				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}> Mail to </h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setEmailModal(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">


						<ReactTags
							ref={reactTags}
							tags={tags}
							suggestions={suggestions}
							onDelete={onDelete}
							onAddition={onAddition}
							placeholder="Ex: abc@gmail.com, xyz@gmail.com"
						/>
						{mailstatus == "NO_REP" ? (
							<div className='mt-5 mb-lg-15 alert alert-danger'>
								<div className='alert-text font-weight-bold'>{'Please enter a valid repersentative mail-id'}</div>
							</div>
						) : null}
						{mailstatus == "SUCCESS" ? (
							<div className='mt-5 mb-lg-15 alert alert-success'>
								<div className='alert-text font-weight-bold'>{'Mail sent successfully.'}</div>
							</div>
						) : null}
					</div>
					<div className="modal-footer">
						<a onClick={() => sendEmail()} class="btn btn-primary">{localLanguage.send}</a>

					</div>
				</div>

			</Modal>

			{/* begin::Modal */}
			<Modal
				className="modal fade"
				id="kt_modal_select_location"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={visible}
				dialogClassName="modal-lg"
				aria-hidden="true"
			// onHide={dissmissLocation}
			>
				<div className="modal-content">
					<div className="modal-header" style={{ backgroundColor: modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) ? 'transparent' : modalInfo.leadstatus == "WON" ? '#4E9F3D' : '#FF8080' }}>
						<h3 className="modal-title" style={{ textAlign: 'center', width: '100%', marginLeft: 54 }}> {modalInfo && modalInfo.business_name}</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setVisible(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<div className='row g-5 mb-8'>
							<div className="col-xl-6">
								{modalInfo && modalInfo.leadstatus == "WON" && <a style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen" disabled="true">{localLanguage.approved}</a>}
								{modalInfo && modalInfo.leadstatus == "LOST" && <a style={{ height: 40 }} className="btn btn-danger ms-2" disabled="true">{localLanguage.denied}</a>}
								{modalInfo && (modalInfo.leadstatus == "" || modalInfo.leadstatus == null) && <div>
									<a onClick={() => setLeadStatus('WON')} style={{ height: 40 }} className="btn btn-success color-white ms-2 btnGreen" disabled="true">{localLanguage.accept_lead}</a>
									<a onClick={() => setLeadStatus('LOST')} style={{ height: 40, color: '#ffffff !important' }} className="btn btn-danger ms-2">{localLanguage.deny_lead}</a>
								</div>}
							</div>
							<div className="col-xl-6">

								{modalInfo && modalInfo.status_type == "MEETING" &&
									<div className="rowMedium">
										<div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#4E9F3D', marginRight: 20 }}>
											<img src={Meeting} alt="" className="invert-img" />
										</div>
										<h5>{localLanguage.want_meeting}</h5>
									</div>
								}

								{modalInfo && modalInfo.status_type == "PHONE" &&
									<div className="rowMedium">
										<div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#4E9F3D', marginRight: 20 }}>
											<img src={Phone} alt="" className="invert-img" />
										</div>
										<h5>{localLanguage.want_called}</h5>
									</div>
								}
								{modalInfo && modalInfo.status_type == "INFO" &&
									<div className="rowMedium">
										<div className="card-icon-rounded" style={{ backgroundColor: modalInfo && modalInfo.status_type ? statuscolors[modalInfo.status_type] : '#4E9F3D', marginRight: 20 }}>
											<img src={Info} alt="" className="invert-img" />
										</div>
										<h5>{localLanguage.want_more_info}</h5>
									</div>
								}

							</div>
						</div>


						<div className="row g-5 g-xl-8">
							<div className="col-xl-6 card-grey mb-5" style={{ backgroundColor: '#b7cfe0 !important' }}>
								<div className='flex-row mb-8 mt-5'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-building fs-3' style={{ color: '#fff' }}></i>
									</div>

									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.business_name}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.business_name}</a>
									</div>
								</div>

								<div className='flex-row mb-8'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-activity fs-3' style={{ color: '#fff' }}></i>
									</div>

									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_score}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadscore}</a>
									</div>

								</div>

								<div className='flex-row mb-8'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-person fs-3' style={{ color: '#fff' }}></i>
									</div>
									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_name}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactname}</a>
									</div>
								</div>

								<div className='flex-row mb-8'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-envelope fs-3' style={{ color: '#fff' }}></i>
									</div>
									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_email}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactmail}</a>
									</div>
								</div>

								<div className='flex-row mb-8'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-back fs-3' style={{ color: '#fff' }}></i>
									</div>

									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_status}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadstatus ? "WON" : "NA"}</a>
									</div>
								</div>

								<div className='flex-row mb-8'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-calendar-date fs-3' style={{ color: '#fff' }}></i>
									</div>

									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.created_on}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leaddate}</a>
									</div>
								</div>

								<div className='flex-row mb-8'>
									<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
										<i className='bi bi-phone fs-3' style={{ color: '#fff' }}></i>
									</div>

									<div className='ms-5' style={{ marginTop: -5 }}>
										<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.contact_phone}</div>
										<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.contactphone}</a>
									</div>
								</div>

								{leadchat && leadchat.length == 0 && modalInfo.leadcomment != null &&
									<div className='flex-row mb-8'>
										<div className='card-icon-rounded-small' style={{ backgroundColor: '#4E9F3D' }}>
											<i className='bi bi-chat fs-3' style={{ color: '#fff' }}></i>
										</div>

										<div className='ms-5' style={{ marginTop: -5 }}>
											<div className='fs-5 fw-bolder text-gray-600'>{localLanguage.lead_conversation}</div>
											<a className='fs-5 fw-bold text-gray-900'>{modalInfo && modalInfo.leadcomment}</a>
										</div>
									</div>
								}

							</div>
							<div className="col-xl-6 mb-5">

								<div className='fs-5 fw-bolder text-gray-600 mb-5'>{localLanguage.history}</div>


								{
									leadchat && leadchat.map((item, index) => {

										return (
											index % 2 ?
												<div className={'flex-row mb-8'} key={index} style={{ justifyContent: 'flex-end' }}>
													<div className='ms-5 chatbox'>
														<a className='fs-6 fw-bold text-gray-900'>{item.comment}</a>
													</div>
													<div className='card-icon-rounded-small' style={{ backgroundColor: '#34a7ce', padding: 5 }}>
														<img
															alt='Logo'
															className='h-20px logo'
															src={"../media/logos/logo.png"}
														/>
													</div>
												</div>
												:
												<div className={'flex-row mb-8'} key={index} >

													{/* <div className='card-icon-rounded-small' style={{ backgroundColor: '#17D7A0', padding: 5 }}>
														<i className={ 'bi bi-person-fill fs-3'} style={{ color: '#fff' }}></i>
													*/}
													<div className='card-icon-rounded-small' style={{ backgroundColor: '#34a7ce', padding: 5 }}>
														<img
															alt='Logo'
															className='h-20px logo'
															src={"../media/logos/logo.png"}
														/>
													</div>

													<div className='ms-5 chatbox'>
														<a className='fs-6 fw-bold text-gray-900'>{item.comment}</a>
													</div>
												</div>
										)
									})

								}

								<div style={{ position: 'absolute', bottom: 20, right: 15 }}>
									<div className='ms-5'>
										<a onClick={() => { setVisible(false); setEmailModal(true); setMailStatus(null) }} className="btn btn-success color-white ms-2 btnBlue" disabled="true">{localLanguage.send_lead}</a>
									</div>
								</div>
								{/* {commentBox &&
									<div className='ms-5'>
										<textarea
											className='form-control form-control-solid'
											rows={5}
											data-kt-element='input'
											//   placeholder='Type a message'
											onChange={(e) => setLeadComment(e.target.value)}
										></textarea>
									</div>
									} 
									
									<div className='flex-row'>
										<div className = 'ms-5  mt-5'>
											<a className='btn btn-dark ms-2' onClick={() => setCommentbox(!commentBox)}>Add Comment</a>
										</div> 
										<div className='ms-5 mt-5'>
											<a onClick={() => updateLeadData()} style={{ height: 40 }} className="btn btn-success color-white ms-2" disabled="true">Send lead</a>
										</div>
									</div>
								*/}

							</div>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export { DashboardWrapper };
