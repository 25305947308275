/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState, useRef } from 'react';
import { postRep, getLists, updateRep, deleteRep, postAnswer, updateAnswer, deleteAnswer } from '../dashboard/redux/DashboardCrud';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useLang } from '../../../_metronic/i18n/Metronici18n'

export default function Salesrep() {
	const { data } = useSelector(({ auth }) => auth.user);
	const [report, setReport] = useState([]);
	const [createModule, setCreateModule] = useState(false);
	const [createAnswerModule, setCreateAnswerModule] = useState(false);
	const [editModule, setEditModule] = useState(false);
	const [editId, setEditId] = useState('');
	const [ansEditId, setAnsEditId] = useState('');
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState('');
	const [answer, setAnswer] = useState('');
	const [email, setEmail] = useState('');
	const textInput = useRef(null);
	const [successTitle, setSuccessTitle] = useState('')
	const [successQus, setSuccessQus] = useState(false)
	const [answersList, setAnswersList] = useState([])
	const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
	const selectedLang = useLang()
	const [localLanguage, setLocalLanguage] = useState({})
	const [userInfo, setUserInfo] = useState({})

	useEffect(() => {
		if (selectedLang === 'english') {
			setLocalLanguage(englishLang)
		} else {
			setLocalLanguage(swedishLang)
		}
	}, [selectedLang, swedishLang, englishLang]);

	useEffect(
		() => {
			if (data && data.id) {
				setUserInfo(data)
				getSalersrep(data);
				getAnswers(data)
			}
		},
		[data]
	);

	const getSalersrep = (data) => {
		let filter = `?filter[customer_id][eq]=${data.id}&fields=*.*.*.*`;
		getLists('CUSTOME_SALES_REP', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setReport(data);
				} else {
					setReport('');
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const getAnswers = () => {
		let filter = `?sort=-id`;
		getLists('ANSWERS', filter)
			.then(({ data: { data } }) => {
				//console.log('Sales reps', data);

				if (data) {
					setAnswersList(data);
				} else {
					setAnswersList([]);
				}
			})
			.catch((err) => {
				throw err;
			});
	};

	const submitNew = () => {
		if (name === '') {
			alert('Please enter name');
			return false;
		}
		if (email === '') {
			alert('Please enter email');
			return false;
		}
		setLoading(true)
		if (name && email) {
			let value = {
				name: name,
				email: email,
				customer_id: data.id,
				status: 'published'
				//created_at: new Date()
			};
			postRep(value)
				.then(({ data: { data } }) => {
					setLoading(false)
					setName('');
					setEmail('');
					setCreateModule(false)
					getSalersrep(userInfo);
				})
				.catch((err) => {
					setLoading(false)
					setCreateModule(false)
					throw err;
				});
		}
	};

	const submitAnswer = () => {
		if (answer === '') {
			alert('Please enter answer');
			return false;
		}

		if (answer) {
			let value = {
				answer: answer,
				status: 'published'
				//created_at: new Date()
			};
			if (ansEditId) {
				updateAnswer(ansEditId, value)
					.then(({ data: { data } }) => {
						//setCreateModule(false);
						setSuccessQus(true)
						setAnswer(null)
						setAnsEditId(null)
						setCreateAnswerModule(false)
						setSuccessTitle('Answer updated successfully');;
						getAnswers(data.id)
					})
					.catch((err) => {
						throw err;
					});
			} else {
				postAnswer(value)
					.then(({ data: { data } }) => {
						//setCreateModule(false);
						//getSalersrep(data);
						setSuccessQus(true)
						setAnswer(null)
						setCreateAnswerModule(false)
						setSuccessTitle('Answer created successfully')
						getAnswers(data.id)

					})
					.catch((err) => {
						throw err;
					});
			}

		}
	};

	const editRep = (value) => {
		setName(value.name);
		setEmail(value.email);
		setEditId(value.id);
		setEditModule(true);
	};
	const editQues = (value) => {
		setAnswer(value.answer);
		setAnsEditId(value.id);
		setCreateAnswerModule(true);
	};


	const submitEdit = () => {
		if (name === '') {
			alert('Please enter name');
			return false;
		}
		if (email === '') {
			alert('Please enter email');
			return false;
		}
		setLoading(true)
		if (name && email) {
			const value = {
				name: name,
				email: email
				//created_at: new Date()
			};
			updateRep(editId, value)
				.then(({ data: { data } }) => {
					setName('');
					setEmail('');
					setEditModule(false);
					getSalersrep(userInfo);
					setLoading(false)
				})
				.catch((err) => {
					setLoading(false)
					console.log(err);
				});
		}
	};

	const deleteItem = (id) => {

		if (window.confirm('Are you sure you want to delete this rep?')) {
			deleteRep(id);
			setReport(report.filter((item) => item.id !== id));
		}
	};

	const deleteQusItem = (id) => {

		if (window.confirm('Are you sure you want to delete this answer?')) {
			deleteAnswer(id);
			setAnswersList(answersList.filter((item) => item.id !== id));
		}
	};

	return (
		<React.Fragment>

			<h2 className="mb-10">{localLanguage.seller_list}</h2>
			<div className="row g-5 g-xl-8">
				{!report ? (
					<div>{localLanguage.no_seller}</div>
				) : (
					<>
						<div className="card-white">
							<div>
								{/* begin::Header */}
								<div className="card-header border-0">
									<div className="card-toolbar" />
								</div>
								{/* end::Header */}
								<div
									className="align-items-end mb-3"
									style={{ display: 'flex', flexDirection: 'column-reverse' }}
								>
									<a
										onClick={() => {
											setCreateModule(true);
										}}
										class="btn btn-primary"
									>
										<i class="fas fa-plus fs-4 me-2" />{localLanguage.add_sales_rep_title}
									</a>
								</div>

								{/* begin::Body */}
								<div className="card-body mb-2" style={{ backgroundColor: '#f5f8fa' }}>
									<div className="tab-content">
										{/* begin::Tap pane */}
										<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
											{/* begin::Table container */}
											<div className="table-responsive">
												{/* begin::Table */}
												<table className="table table-row-dashed table-row-gray-200 align-middle gs-0">
													{/* begin::Table head */}
													<thead>
														<tr className="border-0">
															<th className="p-0 w-50px" />
															<th className="p-0" />
															<th className="p-0" />
															<th className="p-0" />
														</tr>
													</thead>
													{/* end::Table head */}
													{/* begin::Table body */}
													<tbody>
														<tr>
															<td>
																<p className="text-dark fw-bolder mb-1 fs-6">
																</p>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.name}
																</span>
															</td>

															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.email}
																</span>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																</span>
															</td>
														</tr>
														{report &&
															report.map((item, index) => (
																<tr>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{index + 1}
																		</p>
																	</td>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.name}
																		</p>
																	</td>

																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.email}
																		</p>
																	</td>
																	<td>
																		<div className="flex-row">
																			<a
																				onClick={() => editRep(item)}
																				class="btn btn-light btn-hover-rise"
																			>
																				<i class="bi bi-pencil-fill text-warning fs-5 " />
																			</a>
																			<a
																				onClick={() => { deleteItem(item.id); }}
																				class="btn btn-light btn-hover-rise">
																				<i class="bi bi-trash text-danger fs-5 " />
																			</a>
																		</div>
																	</td>
																</tr>
															))}
													</tbody>
													{/* end::Table body */}
												</table>
											</div>
											{/* end::Table */}
										</div>
										{/* end::Tap pane */}
									</div>
								</div>
								{/* end::Body */}
							</div>
						</div>

						<div className="card-white">
							{successQus &&
								<div class="toast show" role="alert" style={{ position: 'absolute', top: 80, right: 20 }} aria-live="assertive" aria-atomic="true">
									<div class="toast-header">
										<strong class="me-auto"></strong>
										<button type="button" onClick={() => setSuccessQus(false)} class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
									</div>
									<div class="toast-body ">
										<h6 class="alert alert-success">{successTitle}</h6>
									</div>
								</div>
							}
							<div>
								{/* begin::Header */}
								<div className="card-header border-0">
									<div className="card-toolbar" />
								</div>
								{/* end::Header */}
								<div
									className="align-items-end mb-3"
									style={{ display: 'flex', flexDirection: 'column-reverse' }}
								>
									<a
										onClick={() => {
											setCreateAnswerModule(true);
										}}
										class="btn btn-primary"
									>
										<i class="fas fa-plus fs-4 me-2" />{localLanguage.add_answer}
									</a>
								</div>

								{/* begin::Body */}
								<div className="card-body mb-2" style={{ backgroundColor: '#f5f8fa' }}>
									<div className="tab-content">
										{/* begin::Tap pane */}
										<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
											{/* begin::Table container */}
											<div className="table-responsive">
												{/* begin::Table */}
												<table className="table table-row-dashed table-row-gray-200 align-middle gs-0">
													{/* begin::Table head */}
													<thead>
														<tr className="border-0">
															<th className="p-0 w-50px" />
															<th className="p-0" />
															<th className="p-0" />
														</tr>
													</thead>
													{/* end::Table head */}
													{/* begin::Table body */}
													<tbody>
														<tr>
															<td>
																<p className="text-dark fw-bolder mb-1 fs-6">
																</p>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																	{localLanguage.answer}
																</span>
															</td>
															<td>
																<span className="text-muted fw-bold d-block">
																</span>
															</td>
														</tr>
														{answersList &&
															answersList.map((item, index) => (
																<tr>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{index + 1}
																		</p>
																	</td>
																	<td>
																		<p className="text-dark fw-bolder mb-1 fs-6">
																			{item.answer}
																		</p>
																	</td>
																	<td>
																		<div className="flex-row">
																			<a
																				onClick={() => editQues(item)}
																				class="btn btn-light btn-hover-rise"
																			>
																				<i class="bi bi-pencil-fill text-warning fs-5 " />
																			</a>
																			<a
																				onClick={() => { deleteQusItem(item.id); }}
																				class="btn btn-light btn-hover-rise">
																				<i class="bi bi-trash text-danger fs-5 " />
																			</a>
																		</div>
																	</td>
																</tr>
															))}
													</tbody>
													{/* end::Table body */}
												</table>
											</div>
											{/* end::Table */}
										</div>
										{/* end::Tap pane */}
									</div>
								</div>
								{/* end::Body */}
							</div>
						</div>
					</>)}
			</div>
			{/* start::Email modal */}

			<Modal
				className="modal fade"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={createModule}
				dialogClassName="modal-lg"
				aria-hidden="false"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}>
							{localLanguage.add_sales_rep_title}
						</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setCreateModule(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<form>
							<div class="mb-10">
								<label for="exampleFormControlInput1" class="required form-label">
									{localLanguage.name}
								</label>
								<input
									ref={textInput}
									type="text"
									class="form-control form-control-solid"
									placeholder="Rep name"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</div>
							<div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.email}
								</label>
								<input
									type="text"
									class="form-control form-control-solid"
									placeholder="name@example.com"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</div>
							<button disabled={loading} onClick={() => submitNew()} type="button" class="btn btn-primary">
							{loading ? (
									<span className='indicator-progress' style={{ display: 'block' }}>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								) : localLanguage.submit}
							</button>
						</form>
					</div>
				</div>
			</Modal>

			{/* CREATE QUESTION MODAL */}
			<Modal
				className="modal fade"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={createAnswerModule}
				dialogClassName="modal-lg"
				aria-hidden="false"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}>
							{' '}
							{localLanguage.add_answer}
						</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setCreateAnswerModule(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<form onSubmit={e => { e.preventDefault(); }}>
							<div class="mb-10">
								<label for="exampleFormControlInput1" class="required form-label">
									{localLanguage.answer}
								</label>
								<textarea
									// ref={textInput}
									// type="text"
									class="form-control form-control-solid"
									placeholder="Answer"
									rows={3}
									value={answer}
									onChange={(e) => {
										setAnswer(e.target.value);
									}}
								/>
							</div>
							<button onClick={() => submitAnswer()} type="button" class="btn btn-primary">
								{localLanguage.submit}
							</button>
						</form>
					</div>
				</div>
			</Modal>
			{/* Edit::Email modal */}

			<Modal
				className="modal fade"
				data-backdrop="static"
				tabIndex={-1}
				role="dialog"
				show={editModule}
				dialogClassName="modal-lg"
				aria-hidden="false"
			>
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-title" style={{ width: '100%', marginLeft: 5 }}>
							{' '}
							Redigera säljare
						</h3>
						<div className="btn btn-icon btn-sm btn-light-primary" onClick={() => setEditModule(false)}>
							<KTSVG className="svg-icon-2" path="/media/icons/duotune/arrows/arr061.svg" />
						</div>
					</div>
					<div className="modal-body">
						<form onSubmit={e => { e.preventDefault(); }}>
							<div class="mb-10">
								<label for="exampleFormControlInput1" class="required form-label">
									{localLanguage.name}
								</label>
								<input
									ref={textInput}
									type="text"
									class="form-control form-control-solid"
									placeholder="Rep name"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</div>
							<div class="mb-10">
								<label for="exampleFormControlInput12" class="required form-label">
									{localLanguage.email}
								</label>
								<input
									type="text"
									class="form-control form-control-solid"
									placeholder="name@example.com"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</div>
							<button disabled={loading} onClick={() => { submitEdit() }} type="button" class="btn btn-primary">
								{/* {loading ? localLanguage.submit} */}
								{loading ? (
									<span className='indicator-progress' style={{ display: 'block' }}>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
									</span>
								) : localLanguage.submit}
							</button>
						</form>
					</div>
				</div>
			</Modal>
		</React.Fragment>
	);
}

export { Salesrep };
