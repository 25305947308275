/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { shallowEqual, useSelector } from 'react-redux'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { getUserByToken } from '../../../../app/modules/auth/redux/AuthCRUD'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'

export default function AsideMenuMain() {
  const intl = useIntl()
  const { data } = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch()
  const { swedishLang, englishLang } = useSelector(({ auth }) => auth);
  const selectedLang = useLang()
  const [localLanguage, setLocalLanguage] = useState({})
  useEffect(() => {
    if (selectedLang === 'english') {
      setLocalLanguage(englishLang)
    } else {
      setLocalLanguage(swedishLang)
    }
  }, [selectedLang, swedishLang, englishLang]);


  useEffect(async () => {
    const { data: user } = await getUserByToken()
    dispatch(auth.actions.fulfillUser(user))
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={localLanguage.overview}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/list'
        icon='/media/icons/duotune/general/gen010.svg'
        title={localLanguage.list}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/salesrep'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.add_sales_rep_title}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/review'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.review}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/meeting'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.meeting_guide}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/crm'
        icon='/media/icons/duotune/general/gen049.svg'
        title={localLanguage.crm_connection}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/pricing'
        icon='/media/icons/duotune/general/gen009.svg'
        title={'Öka konvertering'}
        fontIcon='bi-app-indicator'
      /> */}
      <AsideMenuItem
        to='/reports'
        icon='/media/icons/duotune/general/gen035.svg'
        title={localLanguage.special_report}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/sales-training'
        icon='/media/icons/duotune/general/gen009.svg'
        title={localLanguage.sales_training}
        fontIcon='bi-app-indicator'
        imgs={"../media/logos/lock.png"}
        lock={data.islock}
      />
      <div className="support-section">
        <img
          alt='Logo'
          className='logo support'
          src={"../media/logos/support.png"}
        />
      </div>
    </>
  )
}
