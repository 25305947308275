
export default function Card({
    image,
    title,
    designation,
    buttonName,
    iconColor,
    onclick,
    email,
    status_type, data
}) {
    return (
       <div className={ (data.leadstatus == '' ||  data.leadstatus == null) && !data.isread ? 'Card-small bordernew' :  'Card-small'} onClick={onclick}>
           {(data.leadstatus == '' ||  data.leadstatus == null) && !data.isread && <p className={'topbarInfo'}>Ny!</p>}
           <div className='card-icon-rounded' style={{backgroundColor:iconColor}}>
                <img src={image} alt="" className='invert-img'/>
           </div>
            <h4 className="lead_title">{title}</h4>
            <h5 className="lead_desig">{designation}</h5>
            {/* <h5  className="lead_desig">{email}</h5> */}
            <h5  className="lead_desig">{status_type}</h5>
            <a onClick={onclick} className="btn btn-default" style={{height:26, lineHeight:'6px', fontSize:10, backgroundColor:'#41c4f1'}}>{buttonName}</a>
       </div>
    )
}
