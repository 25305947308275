import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { ListWrapper } from '../pages/dashboard/List'
import Boostconverstion from '../pages/pricing/Pricinglist'
import Reports from '../pages/reports/'
import Salesrep from '../pages/salesrepo/';
import Meeting from '../pages/meeting';
import Review from '../pages/review';
import CRM from '../pages/crm';
import SalesTraining from '../pages/sales-training';
import { Feedback } from '../../app/modules/auth/components/Feedback';

export function PrivateRoutes() {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))

  return (
    // <Suspense fallback={<FallbackView />}>
    <Suspense fallback={false}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/list' component={ListWrapper} />
        <Route path='/pricing' component={Boostconverstion} />
        <Route path='/reports' component={Reports} />
        <Route path='/salesrep' component={Salesrep} />
        <Route path='/meeting' component={Meeting} />
        <Route path='/review' component={Review} />
        <Route path='/crm' component={CRM} />
        <Route path='/sales-training' component={SalesTraining} />
        <Route path='/feedback/:leadid/:salesrepid/:cusid' component={Feedback} />
        {/* <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect from='/login' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
