import axios from 'axios'
import { API_URL, DATAURLS } from '../../../../_metronic/helpers/components/constant'



// Server should return AuthModel
export function getLists(table,params) {
  const API = `${API_URL}${DATAURLS[table].url}`

  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken;

  return axios.get(`${API}${params}`, {
    params: {},
    headers : {
      'Authorization': `Bearer ${token}`
    }
  })
}

export function updateLeads(id, param) {
  const API = `${API_URL}${DATAURLS.LEADS.url}/${id}`
  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.patch(`${API}`, 
    param,
    headers
  )
}

export function updateUser(id, value) {
  const API = `${API_URL}${DATAURLS.USERS.url}/${id}`
  let param = {
    "pricing_status": value
  }
  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.patch(`${API}`, 
    param,
    headers
  )
}

export function postRep(param) {
  const API = `${API_URL}${DATAURLS.CUSTOME_SALES_REP.url}`
  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.post(`${API}`, 
    param,
    headers
  )
}

export function updateRep(id, param) {
  const API = `${API_URL}${DATAURLS.CUSTOME_SALES_REP.url}/${id}`

  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.patch(`${API}`, 
    param,
    headers
  )
}


export function deleteRep(id) {
  const API = `${API_URL}${DATAURLS.CUSTOME_SALES_REP.url}/${id}`

  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.delete(`${API}`, 
    headers
  )
}

export function postMail(param) {
  const API = `${API_URL}${DATAURLS.SEND_MAIL.url}`
  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.post(`${API}`, 
    param,
    headers
  )
}

export function postChat(param) {
  const API = `${API_URL}${DATAURLS.LEAD_CHATS.url}`
  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.post(`${API}`, 
    param,
    headers
  )
}

export function postAnswer(param) {
  const API = `${API_URL}${DATAURLS.ANSWERS.url}`
  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.post(`${API}`, 
    param,
    headers
  )
}

export function updateAnswer(id, param) {
  const API = `${API_URL}${DATAURLS.ANSWERS.url}/${id}`

  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.patch(`${API}`, 
    param,
    headers
  )
}


export function deleteAnswer(id) {
  const API = `${API_URL}${DATAURLS.ANSWERS.url}/${id}`

  let token = JSON.parse(localStorage.getItem("persist:salescrm_user")).accessToken

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  return axios.delete(`${API}`, 
    headers
  )
}

export function getFeedback(table,params) {
  const API = `${API_URL}${DATAURLS[table].url}`


  return axios.get(`${API}${params}`, {
    params: {}
  })
}