
export default function BorderedCard({
    title,
    borderColor,
    iconColor,
    icon, 
    subTitle,
    onclick,
}) {
    return (
       <div onClick={onclick} className='Card-horizontal' style={{borderLeftColor:borderColor}}>
            <div className="flex-row justify-between">
                <h6 style={{ fontSize:12}}>{title}</h6>
                <i className={icon} style={{color:iconColor}}></i>
            </div>   
            <div className="flex-row justify-between mt-5">
                 <h6 style={{ fontSize:10, color:'#ccc'}}>{subTitle}</h6>
                 {/* <a href="#" className="btn btn-light-dark flex-row" style={{display:'flex', lineHeight:'10px',padding:6, fontSize:10}}><i className={'bi bi-clock fs-1x'} ></i> {buttonName}</a> */}
            </div>
       </div>
    )
}
